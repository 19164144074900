@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.projects {
  &__items {
    margin-bottom: rem(44.8);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    &--mirrored {
      flex-direction: row-reverse;
    }

    &:not(:last-of-type) {
      margin-bottom: rem(102.4);
    }
  }

  img,
  &__content {
    max-width: 584px;
    width: 100%;
    border-radius: var(--radius-main);
  }

  &__num,
  h3 {
    margin-bottom: 16px;
  }

  &__desc {
    margin-bottom: rem(38.4);
  }

  &__result {
    margin-bottom: 8px;
    color: var(--color-dark);
  }

  .btn {
    display: block;
    max-width: fit-content;
    margin: 0 auto;
  }

  &--services {
    .projects__items {
      margin-bottom: 0;
    }
  }

  @include media(tablet-wide) {
    img {
      width: 45%;
      height: auto;
    }
  }

  @include media(tablet) {
    &__items {
      margin-bottom: 30px;
    }

    &__item {
      &:not(:last-of-type) {
        margin-bottom: 66px;
      }
    }

    &__desc {
      margin-bottom: 24px;
    }
  }

  @include media(mobile-l) {
    &__item {
      flex-direction: column;
    }

    img,
    &__content {
      max-width: 100%;
      width: 100%;
      border-radius: var(--radius-medium);
    }

    img {
      aspect-ratio: 5/3;
    }

    &__num,
    .title {
      margin-bottom: 8px;
    }
  }

  @include media(mobile-s) {
    &__item {
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }
  }
}

.page {
  &--projects {
    .projects__items {
      margin-bottom: 0;
    }

    .projects__btn {
      display: none;
    }
  }
}
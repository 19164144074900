@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.services {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    gap: 24px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 128px 22px 24px;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 24px;
      left: 50%;
      width: 80px;
      height: 80px;
      transform: translateX(-50%);
    }

    &--installation::before {
      background-image: url("../images/icons/installation-icon.svg");
    }

    &--repair::before {
      background-image: url("../images/icons/repair-icon.svg");
    }

    &--sales::before {
      background-image: url("../images/icons/sales-icon.svg");
    }

    &--energy::before {
      background-image: url("../images/icons/energy-icon.svg");
    }

    &--environment::before {
      background-image: url("../images/icons/heart-icon.svg");
    }

    &--cost::before {
      background-image: url("../images/icons/check-icon.svg");
    }

    h3 {
      text-align: center;
    }

    .btn {
      width: 100%;
    }
  }

  &__slides {
    min-height: 844px;
    margin-bottom: 24px;
  }

  &__slide {
    align-self: stretch;
    padding: 24px 22px;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);

    img {
      max-width: 343px;
      width: 100%;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: rem(25.6);
    }

    ul {
      padding-left: rem(24);
    }

    li {
      list-style: disc;
    }

    .btn {
      width: 100%;
      align-self: flex-end;
    }
  }

  &__article {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__content {
    margin-bottom: rem(38.4);
  }

  &--products {
    .services__content {
      margin-bottom: 16px;
    }
  }

  &--benefits {
    .services__content {
      margin-bottom: 0;
    }
  }

  @include media(tablet) {
    &__grid {
      gap: 16px;
    }

    &__item {
      border-radius: var(--radius-medium);
      padding: 94px 10px 16px;

      &::before {
        width: 60px;
        height: 60px;
        top: 16px;
      }
    }

    &__content {
      margin-bottom: 24px;
    }

    &__slides {
      min-height: fit-content;
    }

    &__slide {
      height: auto;
      align-self: stretch;
      padding: 16px;
      border-radius: var(--radius-medium);
    }

    &--home {
      .services__content p {
        font-size: 16px;
        text-align: center;
      }
    }
  }

  @include media(mobile-l) {
    .services__grid {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: auto;
    }

    .services__item:last-child {
      grid-column: 1 / span 2;
    }
  }

  @include media(mobile-m) {
    &__slides {
      margin-bottom: 8px;
    }

    &__slide {
      img {
        max-width: 100%;
        height: auto;
        aspect-ratio: 4 / 3;
      }
    }

    .services__grid {
      grid-template-columns: 1fr;
    }

    .services__item:last-child {
      grid-column: auto;
    }
  }
}
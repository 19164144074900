@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.about {
  margin: 0 auto;

  h2 {
    margin-bottom: 16px;
  }

  p {
    max-width: 801px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  &__slider {
    position: relative;
    margin-bottom: rem(38.4);
    padding-bottom: rem(38.4);
  }

  &__slides {
    align-items: stretch;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    border-radius: var(--radius-main);
    overflow: hidden;

    & > p {
      margin-bottom: 24px;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    min-height: 722px;
    padding: 24px 24px 35px;
  }

  &__intro {
    margin-bottom: 24px;
  }

  &__img {
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: var(--radius-main);
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: 16px;
  }

  &__item {
    padding: 24px;
    border-radius: var(--radius-main);
    background-color: var(--color-white);

    p {
      text-align: left;
    }
  }

  [class].swiper {
    .swiper-pagination,
    .swiper-button {
      position: absolute;
    }

    .swiper-pagination {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .swiper-button {
      top: 60%;
      transform: translateY(-60%);

      &-prev {
        left: 24px;
      }

      &-next {
        right: 24px;
        left: unset;
      }

      &-disabled {
        display: none;
      }
    }
  }

  &--mission {
    padding: rem(127) 0 rem(140.8);
  }

  &--services {
    padding: rem(70.4) 0 rem(70.4);

    h2 {
      margin-bottom: 20px;
    }

    .btn {
      display: block;
      width: fit-content;
      margin: 0 auto;
    }
  }

  @include media(tablet-wide) {
    &__slider {
      margin-bottom: 30px;
      padding-bottom: 60px;
    }

    [class].swiper .swiper-button {
      bottom: 0;
      top: unset;
      width: 40px;
      height: 40px;
      transform: translateY(0);
    }

    [class].swiper .swiper-pagination {
      bottom: 6px;
    }
  }

  @include media(tablet) {
    &--mission,
    &--services {
      padding: rem(44) 0;
    }

    &__slide {
      justify-content: flex-start;
      height: auto;
      border-radius: var(--radius-medium);
    }

    &__img {
      border-radius: var(--radius-medium);
    }

    &__inner {
      min-height: 500px;
      padding: 16px;
    }

    &__item {
      padding: 16px;
      border-radius: var(--radius-medium);

      p {
        font-size: 16px;
      }
    }
  }

  @include media(mobile-l) {
    &--mission,
    &--services {
      padding: rem(20) 0;
    }

    &__items {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }

    &__inner {
      min-height: max-content;
    }
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.result {
  padding: rem(70.4) 0 rem(140.8);

  .title {
    margin-bottom: 16px;
  }

  &__desc {
    max-width: 801px;
    width: 100%;
    margin: 0 auto 32px;
    text-align: center;
  }

  &__review {
    position: relative;
  }

  &__img {
    border-radius: var(--radius-main);
  }

  &__content {
    position: absolute;
    left: 24px;
    bottom: 24px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 16px 24px;
    max-width: 675px;
    width: 100%;
    padding: 24px;
    border-radius: var(--radius-main);
    background-color: var(--color-white);

    img {
      grid-row: span 2;
      border-radius: var(--radius-full);
    }

    h3 {
      margin-bottom: 0;
    }
  }

  @include media(tablet) {
    padding: 0 0 rem(44);

    &__img {
      border-radius: var(--radius-medium);
    }

    &__content {
      max-width: 80%;
      padding: 16px;
      border-radius: var(--radius-medium);
    }
  }

  @include media(mobile-l) {
    &__content {
      gap: 16px;

      img {
        width: 60px;
        height: auto;
      }

      p {
        font-size: 16px;
      }
    }
  }

  @include media(mobile-m) {
    &__img {
      height: auto;
      margin-bottom: 30px;
      aspect-ratio: 4 / 3;
    }

    &__content {
      position: static;
      max-width: 100%;
      border: 2px solid var(--color-gray-550);
    }
  }

  @include media(mobile-s) {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.process {
  &__items {
    padding-left: rem(70.4);
    counter-reset: num;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);

    &:not(:last-of-type) {
      margin-bottom: 18px;
    }

    &::before {
      content: "0" counter(num);
      counter-increment: num;
      position: absolute;
      top: 0;
      left: rem(-70.4);
      font-size: clamp(36px, 2.5rem + 1vi, 48px);
      line-height: 1.21;
      font-weight: var(--fw-600);
      color: var(--color-dark);
    }
  }

  img {
    max-width: 484px;
    width: 100%;
    height: auto;
    margin-right: 24px;
    border-radius: var(--radius-btn);
  }

  h3 {
    margin-bottom: 16px;
  }

  @include media(tablet) {
    &__items {
      padding-left: 66px;
    }

    &__item {
      padding: 16px;
      border-radius: var(--radius-medium);

      &::before {
        left: -66px;
        font-size: clamp(30px, 1.5rem + 1vi, 38px);
      }
    }

    img {
      width: 40%;
      margin-right: 16px;
      border-radius: var(--radius-medium);
    }
  }

  @include media(mobile-l) {
    &__item {
      flex-direction: column;
    }

    img {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  @include media(mobile-s) {
    &__items {
      padding-left: 0;
    }

    &__item {
      padding-top: 88px;

      &::before {
        left: 16px;
        top: 16px;
      }
    }
  }
}
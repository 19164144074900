@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.reviews {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    gap: 24px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);
  }

  &__avatar {
    margin-right: 24px;
    border-radius: var(--radius-full);
  }

  &__content {
    h3,
    img {
      margin-bottom: 14px;
    }
  }

  @include media(tablet) {
    &__grid {
      gap: 16px;
    }

    &__item {
      flex-direction: column;
      justify-content: flex-start;
      padding: 16px;
      border-radius: var(--radius-medium);
    }

    &__avatar {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }
  }
}
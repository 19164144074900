@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-black: #111;
  --color-dark: #424244;
  --color-gray-300: #eceef2;
  --color-gray-500: #9fa0a3;
  --color-gray-550: #9fa0a380;
  --color-gray-600: #666;
  --color-gray-970: #000000b3;
  --color-green-400: #68b12d;
  --radius-main: 24px;
  --radius-medium: 16px;
  --radius-btn: 32px;
  --radius-full: 50%;
  --gradient-main: radial-gradient(circle, #ffffffc2 40%, #9fa0a385 100%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-gray-500);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(18px, 2rem + 1vi, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

section {
  padding: 4.4rem 0;
}

@media only screen and (max-width: 992px) {
  section {
    padding: 2.75rem 0;
  }
}

@media only screen and (max-width: 576px) {
  section {
    padding: 1.25rem 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .overlay:before {
    background: var(--gradient-main);
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, .title, .name {
  line-height: 1.21;
  font-weight: var(--fw-600);
  color: var(--color-dark);
  overflow: hidden;
}

h1 {
  font-size: clamp(42px, 2.5rem + 1vi, 56px);
}

h2 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 2.8rem;
  font-size: clamp(36px, 2.5rem + 1vi, 48px);
}

h3 {
  margin-bottom: 16px;
  font-size: clamp(24px, 2rem + 1vi, 32px);
}

h4 {
  font-size: clamp(18px, .5rem + 1vi, 20px);
  line-height: 1.5;
  font-weight: var(--fw-600);
  color: var(--color-dark);
  margin-bottom: 8px;
}

.title {
  font-size: clamp(28px, 2rem + 1vi, 36px);
}

.name {
  font-size: clamp(20px, 2rem + 1vi, 24px);
}

a, .copyright {
  font-size: 16px;
  line-height: 1.21;
  font-weight: var(--fw-500);
  color: var(--color-dark);
}

p, time, .author {
  line-height: 1.5;
  font-weight: var(--fw-500);
  color: var(--color-gray-500);
}

p {
  font-size: clamp(18px, .5rem + 1vi, 20px);
}

time, .author {
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  h1 {
    font-size: clamp(36px, 1.5rem + 1vi, 42px);
  }

  h2 {
    margin-bottom: 24px;
    font-size: clamp(30px, 1.2rem + 1vi, 38px);
  }

  h3, .title {
    font-size: clamp(24px, 1rem + 1vi, 28px);
  }

  .name {
    font-size: clamp(20px, 1rem + 1vi, 22px);
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  padding: 23px 0 34px;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

@media only screen and (max-width: 1180px) {
  .header {
    padding: 15px 0;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav .logo {
  max-width: 272px;
  width: 100%;
  margin-right: 20px;
}

@media only screen and (max-width: 1180px) {
  .nav .logo {
    max-width: 220px;
  }
}

.nav img {
  height: auto;
}

.nav__inner {
  max-width: 825px;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    height: calc(var(--vh));
    max-width: 100%;
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin: 0 20px 0 0;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 32px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  text-transform: capitalize;
  transition: border-bottom .4s ease-in-out;
}

.nav__link.true {
  font-weight: var(--fw-800);
}

.nav__link:hover {
  border-bottom: 2px solid var(--color-dark);
}

.nav__btns {
  align-items: center;
  gap: 8px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

[class].btn {
  font-weight: var(--fw-600);
  color: var(--color-dark);
  text-align: center;
  border-radius: var(--radius-btn);
  background-color: var(--color-gray-300);
  margin: 0;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1;
  transition-property: background-color, color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
}

[class].btn--reverse, [class].btn:hover, [class].btn.active {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

[class].btn--reverse:hover {
  background-color: var(--color-gray-300);
  color: var(--color-dark);
}

[class].swiper .slider__nav, [class].swiper .swiper-pagination {
  align-items: center;
  display: flex;
}

[class].swiper .slider__nav {
  justify-content: center;
  gap: 24px;
}

[class].swiper .swiper-button {
  width: 48px;
  height: 48px;
  margin-top: 0;
  position: relative;
  inset: 0;
  transform: translate(0);
}

[class].swiper .swiper-button:after {
  content: "";
  background-image: url("../images/icons/next-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  inset: 0;
}

[class].swiper .swiper-button-prev:after {
  transform: rotate(180deg);
}

[class].swiper .swiper-pagination {
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px 16px;
  position: static;
  transform: translate(0);
}

[class].swiper .swiper-pagination-bullet {
  background: var(--color-dark);
  margin: 0;
}

[class].swiper .swiper-pagination-bullet:not(:last-child) {
  margin-right: 16px;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-black);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero {
  padding: 0;
}

.hero__inner {
  min-height: 648px;
  background-position: 50% -18px;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding: 4.5rem 0 10rem;
  display: flex;
}

.hero__content {
  z-index: 1;
  max-width: 802px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.hero h1 {
  margin-bottom: 16px;
}

.hero p {
  width: 70%;
  margin: 0 auto 2.4rem;
}

.hero--services .hero__inner {
  min-height: 735px;
  background-position: 50% 0;
  background-size: contain;
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    min-height: 400px;
    padding: 2.75rem 10px;
  }

  .hero--home .hero__inner {
    background-position: 100% 0;
  }

  .hero--services .hero__inner {
    min-height: 500px;
    background-position: 50% 10%;
    padding: 3.75rem 10px 2.75rem;
  }

  .hero--services h1 {
    width: 65%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 576px) {
  .hero p {
    width: 100%;
    color: var(--color-gray-600);
    margin-bottom: 30px;
  }

  .hero--services .hero__inner {
    min-height: 450px;
    padding: 2.75rem 10px;
  }

  .hero--services h1 {
    width: 85%;
  }
}

@media only screen and (min-width: 1601px) {
  .hero--home .hero__inner {
    background-position: 50% 69%;
  }
}

.services__grid {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 24px;
  display: grid;
}

.services__item {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  flex-direction: column;
  justify-content: space-between;
  padding: 128px 22px 24px;
  display: flex;
  position: relative;
}

.services__item:before {
  content: "";
  width: 80px;
  height: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.services__item--installation:before {
  background-image: url("../images/icons/installation-icon.svg");
}

.services__item--repair:before {
  background-image: url("../images/icons/repair-icon.svg");
}

.services__item--sales:before {
  background-image: url("../images/icons/sales-icon.svg");
}

.services__item--energy:before {
  background-image: url("../images/icons/energy-icon.svg");
}

.services__item--environment:before {
  background-image: url("../images/icons/heart-icon.svg");
}

.services__item--cost:before {
  background-image: url("../images/icons/check-icon.svg");
}

.services__item h3 {
  text-align: center;
}

.services__item .btn {
  width: 100%;
}

.services__slides {
  min-height: 844px;
  margin-bottom: 24px;
}

.services__slide {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  align-self: stretch;
  padding: 24px 22px;
}

.services__slide img {
  max-width: 343px;
  width: 100%;
  margin-bottom: 20px;
}

.services__slide p {
  margin-bottom: 1.6rem;
}

.services__slide ul {
  padding-left: 1.5rem;
}

.services__slide li {
  list-style: disc;
}

.services__slide .btn {
  width: 100%;
  align-self: flex-end;
}

.services__article {
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.services__content {
  margin-bottom: 2.4rem;
}

.services--products .services__content {
  margin-bottom: 16px;
}

.services--benefits .services__content {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .services__grid {
    gap: 16px;
  }

  .services__item {
    border-radius: var(--radius-medium);
    padding: 94px 10px 16px;
  }

  .services__item:before {
    width: 60px;
    height: 60px;
    top: 16px;
  }

  .services__content {
    margin-bottom: 24px;
  }

  .services__slides {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }

  .services__slide {
    height: auto;
    border-radius: var(--radius-medium);
    align-self: stretch;
    padding: 16px;
  }

  .services--home .services__content p {
    text-align: center;
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .services .services__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  .services .services__item:last-child {
    grid-column: 1 / span 2;
  }
}

@media only screen and (max-width: 576px) {
  .services__slides {
    margin-bottom: 8px;
  }

  .services__slide img {
    max-width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;
  }

  .services .services__grid {
    grid-template-columns: 1fr;
  }

  .services .services__item:last-child {
    grid-column: auto;
  }
}

.about {
  margin: 0 auto;
}

.about h2 {
  margin-bottom: 16px;
}

.about p {
  max-width: 801px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.about__slider {
  margin-bottom: 2.4rem;
  padding-bottom: 2.4rem;
  position: relative;
}

.about__slides {
  align-items: stretch;
}

.about__slide {
  min-height: 100%;
  border-radius: var(--radius-main);
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

.about__slide > p {
  margin-bottom: 24px;
}

.about__inner {
  min-height: 722px;
  align-items: flex-end;
  padding: 24px 24px 35px;
  display: flex;
  position: relative;
}

.about__intro {
  margin-bottom: 24px;
}

.about__img {
  z-index: -1;
  border-radius: var(--radius-main);
  position: absolute;
  inset: 0;
}

.about__items {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 16px;
  display: grid;
}

.about__item {
  border-radius: var(--radius-main);
  background-color: var(--color-white);
  padding: 24px;
}

.about__item p {
  text-align: left;
}

.about [class].swiper .swiper-pagination, .about [class].swiper .swiper-button {
  position: absolute;
}

.about [class].swiper .swiper-pagination {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.about [class].swiper .swiper-button {
  top: 60%;
  transform: translateY(-60%);
}

.about [class].swiper .swiper-button-prev {
  left: 24px;
}

.about [class].swiper .swiper-button-next {
  right: 24px;
  left: unset;
}

.about [class].swiper .swiper-button-disabled {
  display: none;
}

.about--mission {
  padding: 7.9375rem 0 8.8rem;
}

.about--services {
  padding: 4.4rem 0;
}

.about--services h2 {
  margin-bottom: 20px;
}

.about--services .btn {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 1180px) {
  .about__slider {
    margin-bottom: 30px;
    padding-bottom: 60px;
  }

  .about [class].swiper .swiper-button {
    bottom: 0;
    top: unset;
    width: 40px;
    height: 40px;
    transform: translateY(0);
  }

  .about [class].swiper .swiper-pagination {
    bottom: 6px;
  }
}

@media only screen and (max-width: 992px) {
  .about--mission, .about--services {
    padding: 2.75rem 0;
  }

  .about__slide {
    height: auto;
    border-radius: var(--radius-medium);
    justify-content: flex-start;
  }

  .about__img {
    border-radius: var(--radius-medium);
  }

  .about__inner {
    min-height: 500px;
    padding: 16px;
  }

  .about__item {
    border-radius: var(--radius-medium);
    padding: 16px;
  }

  .about__item p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .about--mission, .about--services {
    padding: 1.25rem 0;
  }

  .about__items {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .about__inner {
    min-height: max-content;
  }
}

.products h2 {
  margin-bottom: 16px;
}

.products__desc {
  max-width: 801px;
  width: 100%;
  text-align: center;
  margin: 0 auto 2.5rem;
}

.products__filters {
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  display: flex;
}

.products__items {
  min-height: 680px;
  margin-bottom: 24px;
}

.products__item {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  cursor: pointer;
  align-self: stretch;
  padding: 24px;
  position: relative;
}

.products__item:hover:before {
  transform: scale(1.4);
}

.products__item:before {
  content: "";
  height: 48px;
  width: 48px;
  background-image: url("../images/icons/more-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform .4s ease-in-out;
  position: absolute;
  bottom: 24px;
  left: 24px;
  transform: scale(1);
}

.products__item-img {
  width: 100%;
  border-radius: var(--radius-main);
  margin-bottom: 24px;
}

.products__item-nav {
  justify-content: space-between;
  margin-bottom: 16px;
  display: flex;
}

.products__item h3 {
  max-width: 245px;
  width: 100%;
  margin-bottom: 0;
}

.products__item-info, .products__item-details {
  display: none;
}

.products__item-details ul {
  padding-left: 20px;
}

.products__item-details li {
  list-style: disc;
}

.products__link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.products--portfolio h2 {
  margin-bottom: 2.8rem;
}

.products--portfolio h3 {
  max-width: 100%;
}

.products--portfolio .products__items {
  min-height: 708px;
}

@media only screen and (max-width: 1180px) {
  .products__item-nav {
    flex-direction: column;
  }

  .products h3 {
    max-width: 100%;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .products__desc {
    margin-bottom: 30px;
  }

  .products__filters {
    flex-wrap: wrap;
    gap: 16px 8px;
  }

  .products__items {
    min-height: auto;
  }

  .products__item {
    height: auto;
    align-self: stretch;
    padding: 16px 10px 66px;
  }

  .products__item:before {
    width: 40px;
    height: 40px;
    bottom: 16px;
    left: 16px;
  }

  .products__item {
    border-radius: var(--radius-medium);
  }

  .products__item-img {
    max-width: 100%;
    border-radius: var(--radius-medium);
  }

  .products--portfolio {
    padding-bottom: 0;
  }

  .products--portfolio h2 {
    margin-bottom: 30px;
  }

  .products--portfolio .products__items {
    min-height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .products--panels {
    padding: 2.75rem 0;
  }
}

@media only screen and (max-width: 475px) {
  .products__filters {
    flex-flow: column;
  }

  .products__filters .btn {
    width: 100%;
  }
}

.projects__items {
  margin-bottom: 2.8rem;
}

.projects__item {
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  display: flex;
}

.projects__item--mirrored {
  flex-direction: row-reverse;
}

.projects__item:not(:last-of-type) {
  margin-bottom: 6.4rem;
}

.projects img, .projects__content {
  max-width: 584px;
  width: 100%;
  border-radius: var(--radius-main);
}

.projects__num, .projects h3 {
  margin-bottom: 16px;
}

.projects__desc {
  margin-bottom: 2.4rem;
}

.projects__result {
  color: var(--color-dark);
  margin-bottom: 8px;
}

.projects .btn {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  display: block;
}

.projects--services .projects__items {
  margin-bottom: 0;
}

@media only screen and (max-width: 1180px) {
  .projects img {
    width: 45%;
    height: auto;
  }
}

@media only screen and (max-width: 992px) {
  .projects__items {
    margin-bottom: 30px;
  }

  .projects__item:not(:last-of-type) {
    margin-bottom: 66px;
  }

  .projects__desc {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .projects__item {
    flex-direction: column;
  }

  .projects img, .projects__content {
    max-width: 100%;
    width: 100%;
    border-radius: var(--radius-medium);
  }

  .projects img {
    aspect-ratio: 5 / 3;
  }

  .projects__num, .projects .title {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 475px) {
  .projects__item:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

.page--projects .projects__items {
  margin-bottom: 0;
}

.page--projects .projects__btn {
  display: none;
}

.news__items {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 1.2rem;
  margin-bottom: 2.9rem;
  display: grid;
}

.news__item {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  padding-block: 22px;
  padding-inline: 22px;
  position: relative;
}

.news__article:hover .news__image:before {
  transform: scale(1.15);
}

.news__image {
  max-width: 548px;
  width: 100%;
  border-radius: var(--radius-medium);
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.news__image:before {
  content: "";
  width: 52px;
  height: 48px;
  background-image: url("../images/icons/more-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform .4s ease-in-out;
  position: absolute;
  bottom: 24px;
  right: 20px;
  transform: scale(1);
}

.news__img {
  width: 100%;
}

.news__nav {
  align-items: center;
  gap: 24px;
  margin-bottom: 20px;
  display: flex;
}

.news__date, .news__author {
  padding-left: 28px;
  position: relative;
}

.news__date:before, .news__author:before {
  content: "";
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.news__date:before {
  background-image: url("../images/icons/calendar-icon.svg");
}

.news__author:before {
  background-image: url("../images/icons/user-icon.svg");
}

.news h3 {
  margin-bottom: 8px;
}

.news__link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.news .btn {
  min-width: 200px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  display: block;
}

.news--home {
  padding-bottom: 8.8rem;
}

.news--all {
  padding: 4.4rem 0 8.8rem;
}

.news--all h1 {
  text-align: center;
  margin-bottom: 2.8rem;
  font-size: clamp(36px, 2.5rem + 1vi, 48px);
}

.news--all .news__items {
  margin-bottom: 0;
}

.news--single {
  padding: 4.4rem 0 8.8rem;
}

.news--single .news__nav {
  margin-bottom: 32px;
}

.news--single time, .news--single .author {
  font-size: clamp(18px, .5rem + 1vi, 20px);
}

.news--single h1 {
  margin-bottom: 24px;
  font-size: clamp(36px, 2.5rem + 1vi, 40px);
}

.news--single > p {
  margin-bottom: 30px;
}

.news--single > img {
  border-radius: var(--radius-main);
}

.news--single > img:not(:last-of-type) {
  margin-bottom: 4.675rem;
}

@media only screen and (max-width: 992px) {
  .news {
    padding: 2.75rem 0;
  }

  .news__items, .news__nav {
    gap: 16px;
  }

  .news__items {
    margin-bottom: 30px;
  }

  .news__item {
    padding: 16px;
  }

  .news--all {
    padding: 2.75rem 0;
  }

  .news--all h1 {
    margin-bottom: 30px;
    font-size: clamp(30px, 1.2rem + 1vi, 38px);
  }

  .news--single {
    padding: 24px 0 2.75rem;
  }

  .news--single .news__nav, .news--single > p {
    margin-bottom: 16px;
  }

  .news--single time, .news--single .author {
    font-size: 16px;
  }

  .news--single h1 {
    font-size: clamp(30px, 1.2rem + 1vi, 38px);
  }

  .news--single > img {
    border-radius: var(--radius-medium);
  }

  .news--single > img:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .news__items {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .news__image {
    max-width: 100%;
    border-radius: var(--radius-medium);
  }

  .news__image:before {
    width: 40px;
    height: 36px;
  }

  .news__img {
    height: auto;
  }

  .news__picture {
    aspect-ratio: 4 / 2;
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .news {
    padding: 0 0 2.75rem;
  }

  .news__item {
    border-radius: var(--radius-medium);
  }

  .news__img {
    aspect-ratio: 5 / 4;
  }

  .news h3 {
    font-size: clamp(20px, 1rem + 1vi, 22px);
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  padding: 4.4rem 0 8.8rem;
}

.policy h1 {
  text-align: center;
  margin-bottom: 2.8rem;
  font-size: clamp(36px, 2.5rem + 1vi, 48px);
}

.policy h2 {
  font-size: clamp(20px, 2rem + 1vi, 24px);
  font-weight: var(--fw-500);
  text-align: left;
  padding-top: 20px;
}

.policy strong {
  font-weight: var(--fw-700);
}

.policy p, .policy ul {
  margin-bottom: 30px;
}

.policy li, .policy a {
  font-size: clamp(18px, .5rem + 1vi, 20px);
}

.policy li {
  padding-left: 20px;
  position: relative;
}

.policy li:before {
  content: "-";
  position: absolute;
  left: 0;
}

.policy a {
  font-weight: var(--fw-600);
  color: var(--color-green-400);
  border-bottom: 1px solid #0000;
  transition: border-bottom .4s ease-in-out;
}

.policy a:hover {
  border-bottom: 1px solid var(--color-green-400);
}

@media only screen and (max-width: 992px) {
  .policy {
    padding: 24px 0 2.75rem;
  }

  .policy h1 {
    margin-bottom: 16px;
    font-size: clamp(30px, 1.2rem + 1vi, 38px);
  }

  .policy p, .policy ul, .policy h2 {
    margin-bottom: 16px;
  }

  .policy h2 {
    font-size: clamp(20px, 1rem + 1vi, 22px);
  }

  .policy p {
    font-size: 16px;
  }
}

.modal {
  z-index: 100;
  background-color: var(--color-gray-970);
  position: fixed;
  inset: 0;
}

.modal__wrapper {
  max-width: 70%;
  width: 100%;
  max-height: calc(var(--vh)  - 10vh);
  background-color: var(--color-white);
  border-radius: var(--radius-main);
  padding: 2.8rem 4.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
}

.modal__content {
  border: 2px solid var(--color-gray-550);
  border-radius: inherit;
  margin-bottom: 2.3rem;
  padding: 24px;
}

.modal img {
  max-width: 768px;
  width: 100%;
  height: auto;
  aspect-ratio: 4 / 3;
  margin-bottom: 24px;
}

.modal .products__item-nav * {
  font-size: clamp(36px, 2.5rem + 1vi, 48px);
}

.modal .products__item-nav h3 {
  max-width: 630px;
  margin-bottom: 0;
}

.modal .products__item-desc {
  display: none;
}

.modal .products__item-info, .modal .products__item-details, .modal .btn {
  display: block;
}

.modal .products__item-info {
  margin-bottom: 32px;
}

.modal .btn {
  margin: 0 auto;
}

.modal__close {
  width: 27px;
  height: 27px;
  background-image: url("../images/icons/close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 32px;
  right: 32px;
}

@media only screen and (max-width: 992px) {
  .modal__wrapper {
    border-radius: var(--radius-medium);
    padding: 50px 16px 16px;
  }

  .modal__content {
    margin-bottom: 16px;
    padding: 16px;
  }

  .modal__close {
    top: 16px;
    right: 16px;
  }

  .modal img, .modal .products__item-info {
    margin-bottom: 16px;
  }

  .modal .products__item-nav * {
    font-size: clamp(30px, 1rem + 1vi, 38px);
  }
}

@media only screen and (max-width: 768px) {
  .modal__wrapper {
    max-width: 85%;
  }

  .modal img {
    width: 80%;
    margin: 0 auto 16px;
  }
}

@media only screen and (max-width: 576px) {
  .modal__wrapper {
    max-width: 95%;
  }

  .modal .products__item-nav * {
    font-size: 24px;
  }

  .modal .products__item-info, .modal p {
    font-size: 16px;
  }
}

.footer {
  padding: 4rem 0 1.2rem;
}

.footer__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .footer__inner {
    text-align: center;
    flex-direction: column-reverse;
    gap: 20px;
  }
}

.footer__nav {
  align-items: center;
  display: flex;
}

.footer__item:not(:last-child) {
  margin-right: 1.2rem;
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 24px 0;
  }
}

@media only screen and (max-width: 576px) {
  .footer {
    padding: 16px 0;
  }

  .footer__inner {
    gap: 30px;
  }

  .footer__item:not(:last-of-type) {
    margin-right: 16px;
  }
}

.process__items {
  counter-reset: num;
  padding-left: 4.4rem;
}

.process__item {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  justify-content: space-between;
  padding: 24px;
  display: flex;
  position: relative;
}

.process__item:not(:last-of-type) {
  margin-bottom: 18px;
}

.process__item:before {
  content: "0" counter(num);
  counter-increment: num;
  font-size: clamp(36px, 2.5rem + 1vi, 48px);
  line-height: 1.21;
  font-weight: var(--fw-600);
  color: var(--color-dark);
  position: absolute;
  top: 0;
  left: -4.4rem;
}

.process img {
  max-width: 484px;
  width: 100%;
  height: auto;
  border-radius: var(--radius-btn);
  margin-right: 24px;
}

.process h3 {
  margin-bottom: 16px;
}

@media only screen and (max-width: 992px) {
  .process__items {
    padding-left: 66px;
  }

  .process__item {
    border-radius: var(--radius-medium);
    padding: 16px;
  }

  .process__item:before {
    font-size: clamp(30px, 1.5rem + 1vi, 38px);
    left: -66px;
  }

  .process img {
    width: 40%;
    border-radius: var(--radius-medium);
    margin-right: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .process__item {
    flex-direction: column;
  }

  .process img {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 475px) {
  .process__items {
    padding-left: 0;
  }

  .process__item {
    padding-top: 88px;
  }

  .process__item:before {
    top: 16px;
    left: 16px;
  }
}

.reviews__grid {
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
  gap: 24px;
  display: grid;
}

.reviews__item {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  justify-content: space-between;
  padding: 24px;
  display: flex;
}

.reviews__avatar {
  border-radius: var(--radius-full);
  margin-right: 24px;
}

.reviews__content h3, .reviews__content img {
  margin-bottom: 14px;
}

@media only screen and (max-width: 992px) {
  .reviews__grid {
    gap: 16px;
  }

  .reviews__item {
    border-radius: var(--radius-medium);
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
  }

  .reviews__avatar {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .reviews__grid {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

.faq__item {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  overflow: hidden;
}

.faq__item:not(:last-child) {
  margin-bottom: 24px;
}

.faq details {
  overflow: hidden;
}

.faq details[open] summary {
  padding: 24px 5.375rem 8px 24px;
}

.faq details[open] summary:before {
  rotate: none;
}

.faq details[open] summary + div {
  padding: 0 24px 24px;
}

.faq summary {
  font-size: clamp(24px, 2rem + 1vi, 32px);
  line-height: 1.21;
  font-weight: var(--fw-600);
  color: var(--color-dark);
  cursor: pointer;
  padding: 24px 5.375rem 24px 24px;
  position: relative;
}

.faq summary:before {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/collapse-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 24px;
  right: 24px;
  overflow: hidden;
  rotate: 180deg;
}

.faq summary + div {
  padding: 0 24px;
}

@media only screen and (max-width: 992px) {
  .faq__item {
    border-radius: var(--radius-medium);
  }

  .faq__item:not(:last-child) {
    margin-bottom: 16px;
  }

  .faq summary {
    padding: 16px 2.75rem 16px 16px;
    font-size: clamp(20px, 1rem + 1vi, 22px);
  }

  .faq summary:before {
    top: 16px;
    right: 16px;
  }

  .faq details[open] summary {
    padding: 16px 2.75rem 8px 16px;
  }

  .faq details[open] summary + div {
    padding: 0 16px 16px;
  }
}

.single h1 {
  margin-bottom: 16px;
  font-size: clamp(36px, 2.5rem + 1vi, 48px);
}

.single__info {
  max-width: 801px;
  width: 100%;
  text-align: center;
  margin: 0 auto 32px;
}

.single__content {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 32px 24px;
  display: grid;
}

.single__img {
  border-radius: var(--radius-main);
  grid-column: span 2;
}

.single__details {
  border: 2px solid var(--color-gray-550);
  border-radius: var(--radius-main);
  padding: 24px;
}

.single__details h2 {
  text-align: left;
  margin-bottom: 16px;
  font-size: clamp(24px, 2rem + 1vi, 32px);
}

@media only screen and (max-width: 992px) {
  .single h1 {
    font-size: clamp(30px, 1.2rem + 1vi, 38px);
  }

  .single__img, .single__details {
    border-radius: var(--radius-medium);
  }

  .single h2 {
    font-size: clamp(24px, 1rem + 1vi, 28px);
  }
}

@media only screen and (max-width: 768px) {
  .single__content {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .single__img {
    height: auto;
    aspect-ratio: 4 / 3;
    grid-column: auto;
  }
}

.result {
  padding: 4.4rem 0 8.8rem;
}

.result .title {
  margin-bottom: 16px;
}

.result__desc {
  max-width: 801px;
  width: 100%;
  text-align: center;
  margin: 0 auto 32px;
}

.result__review {
  position: relative;
}

.result__img {
  border-radius: var(--radius-main);
}

.result__content {
  max-width: 675px;
  width: 100%;
  border-radius: var(--radius-main);
  background-color: var(--color-white);
  grid-template-columns: repeat(2, auto);
  gap: 16px 24px;
  padding: 24px;
  display: grid;
  position: absolute;
  bottom: 24px;
  left: 24px;
}

.result__content img {
  border-radius: var(--radius-full);
  grid-row: span 2;
}

.result__content h3 {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .result {
    padding: 0 0 2.75rem;
  }

  .result__img {
    border-radius: var(--radius-medium);
  }

  .result__content {
    max-width: 80%;
    border-radius: var(--radius-medium);
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .result__content {
    gap: 16px;
  }

  .result__content img {
    width: 60px;
    height: auto;
  }

  .result__content p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .result__img {
    height: auto;
    aspect-ratio: 4 / 3;
    margin-bottom: 30px;
  }

  .result__content {
    max-width: 100%;
    border: 2px solid var(--color-gray-550);
    position: static;
  }
}

@media only screen and (max-width: 475px) {
  .result__content {
    grid-template-columns: 1fr;
  }
}

/*# sourceMappingURL=main.css.map */

@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3,
.title,
.name {
  overflow: hidden;
  line-height: 1.21;
  font-weight: var(--fw-600);
  color: var(--color-dark);
}

h1 {
  font-size: clamp(42px, 2.5rem + 1vi, 56px);
}

h2 {
  font-size: clamp(36px, 2.5rem + 1vi, 48px);
  margin-bottom: rem(44.8);
  text-align: center;
  text-transform: capitalize;
}

h3 {
  margin-bottom: 16px;
  font-size: clamp(24px, 2rem + 1vi, 32px);
}

h4 {
  margin-bottom: 8px;
  font-size: clamp(18px, 0.5rem + 1vi, 20px);
  line-height: 1.5;
  font-weight: var(--fw-600);
  color: var(--color-dark);
}

.title {
  font-size: clamp(28px, 2rem + 1vi, 36px);
}

.name {
  font-size: clamp(20px, 2rem + 1vi, 24px);
}

a,
.copyright {
  font-size: 16px;
  line-height: 1.21;
  font-weight: var(--fw-500);
  color: var(--color-dark);
}

p,
time,
.author {
  line-height: 1.5;
  font-weight: var(--fw-500);
  color: var(--color-gray-500);
}

p {
  font-size: clamp(18px, 0.5rem + 1vi, 20px);
}

time,
.author {
  font-size: 14px;
}

@include media(tablet) {
  h1 {
    font-size: clamp(36px, 1.5rem + 1vi, 42px);
  }

  h2 {
    font-size: clamp(30px, 1.2rem + 1vi, 38px);
    margin-bottom: 24px;
  }

  h3,
  .title {
    font-size: clamp(24px, 1rem + 1vi, 28px);
  }

  .name {
    font-size: clamp(20px, 1rem + 1vi, 22px);
  }
}
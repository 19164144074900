@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.modal {
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: var(--color-gray-970);

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 70%;
    width: 100%;
    max-height: calc(var(--vh) - 10vh);
    padding: rem(44.8) rem(70.4);
    background-color: var(--color-white);
    border-radius: var(--radius-main);
    overflow-y: scroll;
    transform: translate(-50%, -50%);
  }

  &__content {
    margin-bottom: rem(36.8);
    padding: 24px;
    border: 2px solid var(--color-gray-550);
    border-radius: inherit;
  }

  img {
    max-width: 768px;
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    aspect-ratio: 4/3;
  }

  .products__item-nav {
    * {
      font-size: clamp(36px, 2.5rem + 1vi, 48px);
    }

    h3 {
      max-width: 630px;
      margin-bottom: 0;
    }
  }

  .products__item-desc {
    display: none;
  }

  .products__item-info,
  .products__item-details,
  .btn {
    display: block;
  }

  .products__item-info {
    margin-bottom: 32px;
  }

  .btn {
    margin: 0 auto;
  }

  &__close {
    @include cover-background;

    position: absolute;
    width: 27px;
    height: 27px;
    right: 32px;
    top: 32px;
    background-image: url("../images/icons/close-icon.svg");
  }

  @include media(tablet) {
    &__wrapper {
      padding: 50px 16px 16px;
      border-radius: var(--radius-medium);
    }

    &__content {
      margin-bottom: 16px;
      padding: 16px;
    }

    &__close {
      top: 16px;
      right: 16px;
    }

    img,
    .products__item-info {
      margin-bottom: 16px;
    }

    .products__item-nav * {
      font-size: clamp(30px, 1rem + 1vi, 38px);
    }
  }

  @include media(mobile-l) {
    &__wrapper {
      max-width: 85%;
    }

    img {
      width: 80%;
      margin: 0 auto 16px;
    }
  }

  @include media(mobile-m) {
    &__wrapper {
      max-width: 95%;
    }

    .products__item-nav * {
      font-size: 24px;
    }

    .products__item-info,
    p {
      font-size: 16px;
    }
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.products {
  h2 {
    margin-bottom: 16px;
  }

  &__desc {
    max-width: 801px;
    width: 100%;
    margin: 0 auto rem(40);
    text-align: center;
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 8px;
  }

  &__items {
    min-height: 680px;
    margin-bottom: 24px;
  }

  &__item {
    position: relative;
    align-self: stretch;
    padding: 24px;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);
    cursor: pointer;

    &:hover::before {
      transform: scale(1.4);
    }

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      left: 24px;
      bottom: 24px;
      height: 48px;
      width: 48px;
      transform: scale(1);
      transition: transform 0.4s ease-in-out;
      background-image: url("../images/icons/more-icon.svg");
    }

    &-img {
      width: 100%;
      margin-bottom: 24px;
      border-radius: var(--radius-main);
    }

    &-nav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    h3 {
      max-width: 245px;
      width: 100%;
      margin-bottom: 0;
    }

    &-info,
    &-details {
      display: none;
    }

    &-details {
      ul {
        padding-left: 20px;
      }

      li {
        list-style: disc;
      }
    }
  }

  &__link {

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 2;
    }
  }

  &--portfolio {
    h2 {
      margin-bottom: rem(44.8);
    }

    h3 {
      max-width: 100%;
    }

    .products__items {
      min-height: 708px;
    }
  }

  @include media(tablet-wide) {
    &__item-nav {
      flex-direction: column;
    }

    h3 {
      max-width: 100%;
      margin-bottom: 16px;
    }
  }

  @include media(tablet) {
    &__desc {
      margin-bottom: 30px;
    }

    &__filters {
      flex-wrap: wrap;
      gap: 16px 8px;
    }

    &__items {
      min-height: auto;
    }

    &__item {
      align-self: stretch;
      padding: 16px 10px 66px 10px;
      height: auto;

      &::before {
        bottom: 16px;
        left: 16px;
        width: 40px;
        height: 40px;
      }
    }

    &__item {
      border-radius: var(--radius-medium);

      &-img {
        max-width: 100%;
        border-radius: var(--radius-medium);
      }
    }

    &--portfolio {
      padding-bottom: 0;

      h2 {
        margin-bottom: 30px;
      }

      .products__items {
        min-height: auto;
      }
    }
  }

  @include media(mobile-m) {
    &--panels {
      padding: rem(44) 0;
    }
  }

  @include media(mobile-s) {
    &__filters {
      flex-direction: column;
      flex-wrap: nowrap;

      .btn {
        width: 100%;
      }
    }
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding: rem(70.4) 0 rem(140.8);

  h1 {
    margin-bottom: rem(44.8);
    font-size: clamp(36px, 2.5rem + 1vi, 48px);
    text-align: center;
  }

  h2 {
    padding-top: 20px;
    font-size: clamp(20px, 2rem + 1vi, 24px);
    font-weight: var(--fw-500);
    text-align: left;
  }

  strong {
    font-weight: var(--fw-700);
  }

  p,
  ul {
    margin-bottom: 30px;
  }

  li,
  a {
    font-size: clamp(18px, .5rem + 1vi, 20px);
  }

  li {
    position: relative;
    padding-left: 20px;

    &::before {
      content: "-";
      position: absolute;
      left: 0;
    }
  }

  a {
    font-weight: var(--fw-600);
    color: var(--color-green-400);
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.4s ease-in-out;

    &:hover {
      border-bottom: 1px solid var(--color-green-400);
    }
  }

  @include media(tablet) {
    padding: 24px 0 rem(44);

    h1 {
      margin-bottom: 16px;
      font-size: clamp(30px, 1.2rem + 1vi, 38px);
    }

    p,
    ul,
    h2 {
      margin-bottom: 16px;
    }

    h2 {
      font-size: clamp(20px, 1rem + 1vi, 22px);
    }

    p {
      font-size: 16px;
    }

  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.news {
  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    gap: rem(19.2);
    margin-bottom: rem(46.4);
  }

  &__item {
    position: relative;
    padding-inline: 22px;
    padding-block: 22px;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);
  }

  &__article {
    &:hover .news__image::before {
      transform: scale(1.15);
    }
  }

  &__image {
    position: relative;
    max-width: 548px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: var(--radius-medium);
    overflow: hidden;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      right: 20px;
      bottom: 24px;
      width: 52px;
      height: 48px;
      background-image: url("../images/icons/more-icon.svg");
      transform: scale(1);
      transition: transform 0.4s ease-in-out;
    }
  }

  &__img {
    width: 100%;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 20px;
  }

  &__date,
  &__author {
    position: relative;
    padding-left: 28px;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
    }
  }

  &__date {
    &::before {
      background-image: url("../images/icons/calendar-icon.svg");
    }
  }

  &__author {
    &::before {
      background-image: url("../images/icons/user-icon.svg");
    }
  }

  h3 {
    margin-bottom: 8px;
  }

  &__link {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
    }
  }

  .btn {
    display: block;
    min-width: 200px;
    max-width: fit-content;
    margin: 0 auto;
  }

  &--home {
    padding-bottom: rem(140.8);
  }

  &--all {
    padding: rem(70.4) 0 rem(140.8);

    h1 {
      font-size: clamp(36px, 2.5rem + 1vi, 48px);
      margin-bottom: rem(44.8);
      text-align: center;
    }

    .news__items {
      margin-bottom: 0;
    }
  }

  &--single {
    padding: rem(70.4) 0 rem(140.8);

    .news__nav {
      margin-bottom: 32px;
    }

    time, .author {
      font-size: clamp(18px, 0.5rem + 1vi, 20px);
    }

    h1 {
      margin-bottom: 24px;
      font-size: clamp(36px, 2.5rem + 1vi, 40px);
    }

    & > p {
      margin-bottom: 30px;
    }

    & > img {
      border-radius: var(--radius-main);

      &:not(:last-of-type) {
        margin-bottom: rem(74.8);
      }
    }
  }

  @include media(tablet) {
    padding: rem(44) 0;

    &__items,
    &__nav {
      gap: 16px;
    }

    &__items {
      margin-bottom: 30px;
    }

    &__item {
      padding: 16px;
    }

    &--all {
      padding: rem(44) 0;

      h1 {
        font-size: clamp(30px, 1.2rem + 1vi, 38px);
        margin-bottom: 30px;
      }
    }

    &--single {
      padding: 24px 0 rem(44);

      .news__nav,
      & > p {
        margin-bottom: 16px;
      }

      time,
      .author {
        font-size: 16px;
      }

      h1 {
        font-size: clamp(30px, 1.2rem + 1vi, 38px);
      }

      & > img {
        border-radius: var(--radius-medium);

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
      }
    }
  }

  @include media(mobile-l) {
    &__items {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }

    &__image {
      max-width: 100%;
      border-radius: var(--radius-medium);

      &::before {
        width: 40px;
        height: 36px;
      }
    }

    &__img {
      height: auto;
    }

    &__picture {
      aspect-ratio: 4 / 2;
      height: auto;
    }
  }

  @include media(mobile-m) {
    padding: 0 0 rem(44);

    &__item {
      border-radius: var(--radius-medium);
    }

    &__img {
      aspect-ratio: 5/4;
    }

    h3 {
      font-size: clamp(20px, 1rem + 1vi, 22px);
    }
  }
}
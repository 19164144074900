@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: rem(64) 0 rem(19.2);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media(mobile-l) {
      flex-direction: column-reverse;
      gap: 20px;
      text-align: center;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: rem(19.2);
    }
  }

  @include media(tablet) {
    padding: 24px 0;
  }

  @include media(mobile-m) {
    padding: 16px 0;

    &__inner {
      gap: 30px;
    }

    &__item {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }
}
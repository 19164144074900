@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.faq {
  &__item {
    overflow: hidden;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  details {
    overflow: hidden;

    &[open] {
      summary {
        padding: 24px rem(86) 8px 24px;
      }

      summary::before {
        rotate: 0deg;
      }

      summary + div {
        padding: 0 24px 24px;
      }
    }
  }

  summary {
    position: relative;
    padding: 24px rem(86) 24px 24px;
    font-size: clamp(24px, 2rem + 1vi, 32px);
    line-height: 1.21;
    font-weight: var(--fw-600);
    color: var(--color-dark);
    cursor: pointer;

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      right: 24px;
      top: 24px;
      overflow: hidden;
      width: 32px;
      height: 32px;
      rotate: 180deg;
      background-image: url("../images/icons/collapse-icon.svg");
    }

    + div {
      padding: 0 24px;
    }
  }

  @include media(tablet) {
    &__item {
      border-radius: var(--radius-medium);

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    summary {
      padding: 16px rem(44) 16px 16px;
      font-size: clamp(20px, 1rem + 1vi, 22px);

      &::before {
        top: 16px;
        right: 16px;
      }
    }

    details {
      &[open] {
        summary {
          padding: 16px rem(44) 8px 16px;
        }

        summary + div {
          padding: 0 16px 16px;
        }
      }
    }
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  &__inner {
    display: flex;
    flex-direction: column;
    padding: rem(72) 0 rem(160);
    min-height: 648px;
    background-position: center -18px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    z-index: 1;
    margin: 0 auto;
    max-width: 802px;
    width: 100%;
    text-align: center;
  }

  h1 {
    margin-bottom: 16px;
  }

  p {
    width: 70%;
    margin: 0 auto rem(38.4);
  }

  &--services {
    .hero__inner {
      min-height: 735px;
      background-position: center 0;
      background-size: contain;
    }
  }

  @include media(tablet) {
    &__inner {
      padding: rem(44) 10px;
      min-height: 400px;
    }

    &--home {
      .hero__inner {
        background-position: 100% top;
      }
    }

    &--services {
      .hero__inner {
        min-height: 500px;
        padding: rem(60) 10px rem(44);
        background-position: center 10%;
      }

      h1 {
        width: 65%;
        margin: 0 auto;
      }
    }
  }

  @include media(mobile-m) {
    p {
      width: 100%;
      margin-bottom: 30px;
      color: var(--color-gray-600);
    }

    &--services {
      .hero__inner {
        min-height: 450px;
        padding: rem(44) 10px;
      }

      h1 {
        width: 85%;
      }
    }
  }

  @include media(desktop-l, min) {
    &--home {
      .hero__inner {
        background-position: center 69%;
      }
    }
  }
}
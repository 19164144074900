@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 16px 24px;
  font-weight: var(--fw-600);
  font-size: 16px;
  line-height: 1;
  color: var(--color-dark);
  text-align: center;
  border-radius: var(--radius-btn);
  background-color: var(--color-gray-300);
  transition-property: background-color, color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &--reverse,
  &:hover,
  &.active {
    color: var(--color-white);
    background-color: var(--color-green-400);
  }

  &--reverse {
    &:hover {
      background-color: var(--color-gray-300);
      color: var(--color-dark);
    }
  }
}

*[class].swiper {
  .slider__nav,
  .swiper-pagination {
    display: flex;
    align-items: center;
  }

  .slider__nav {
    justify-content: center;
    gap: 24px;
  }

  .swiper-button {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 48px;
    height: 48px;
    margin-top: 0;
    transform: translate(0);

    &::after {
      @include contain-background;

      content: "";
      position: absolute;
      inset: 0;
      background-image: url("../images/icons/next-icon.svg");
    }

    &-prev {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .swiper-pagination {
    position: static;
    max-width: fit-content;
    padding: 8px 16px;
    transform: translate(0);
  }

  .swiper-pagination-bullet {
    margin: 0;
    background: var(--color-dark);

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.single {
  h1 {
    margin-bottom: 16px;
    font-size: clamp(36px, 2.5rem + 1vi, 48px);
  }

  &__info {
    max-width: 801px;
    width: 100%;
    margin: 0 auto 32px;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 32px 24px;
  }

  &__img {
    grid-column: span 2;
    border-radius: var(--radius-main);
  }

  &__details {
    padding: 24px;
    border: 2px solid var(--color-gray-550);
    border-radius: var(--radius-main);

    h2 {
      margin-bottom: 16px;
      font-size: clamp(24px, 2rem + 1vi, 32px);
      text-align: left;
    }
  }

  @include media(tablet) {
    h1 {
      font-size: clamp(30px, 1.2rem + 1vi, 38px);
    }

    &__img,
    &__details {
      border-radius: var(--radius-medium);
    }

    h2 {
      font-size: clamp(24px, 1rem + 1vi, 28px);
    }
  }

  @include media(mobile-l) {
    &__content {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    &__img {
      grid-column: auto;
      height: auto;
      aspect-ratio: 4 / 3;
    }
  }
}